import React, { useEffect } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { CommonForm, SimpleTable } from '@components/common';
import { defaultFormatDate } from '@constants/Date';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Checkbox, Label } from '@shared/UI';
import { checkStoreStatuses } from '@shared/utils/functions';
import { getCurrencies } from '@store/actions/extra/Currency';
import { getPaIConsumeByPO } from '@store/actions/management/PaI';
import { getCostCenters } from '@store/actions/properties/CostCenter';
import { getPaICategories } from '@store/actions/properties/PaI/Category';
import { getPaIUnits } from '@store/actions/properties/PaI/Unit';
import { getVendors } from '@store/actions/properties/Vendor';
import { selectCurrenciesList, selectCurrencyStatus } from '@store/selectors/extra/Currency';
import { selectPaIConsumePO, selectPaIStatus } from '@store/selectors/management/PaI';
import {
  selectCostCentersList,
  selectCostCenterStatus,
} from '@store/selectors/properties/CostCenter';
import {
  selectPaICategoriesList,
  selectPaICategoryStatus,
} from '@store/selectors/properties/PaI/Category';
import { selectPaIUnitsList, selectPaIUnitStatus } from '@store/selectors/properties/PaI/Unit';
import { selectVendorsList, selectVendorStatus } from '@store/selectors/properties/Vendor';
import { Col, Row } from 'antd';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { ColumnsTableType } from '@components/common/SimpleTable/SimpleTable';
import type { PaIConsumePOModel, PaIModel } from '@model/management/PaI';

const { CaseField, Item, PreviewField } = CommonForm;

const columns: ColumnsTableType<PaIConsumePOModel> = [
  {
    title: <Translation>{(t) => t('titles.PO')}</Translation>,
    dataIndex: 'purchaseOrderNumber',
    key: 'purchaseOrderNumber',
  },
  {
    title: <Translation>{(t) => t('titles.Barcode')}</Translation>,
    dataIndex: 'barcode',
    key: 'barcode',
  },
  {
    title: <Translation>{(t) => t('titles.Demand_Quantity')}</Translation>,
    dataIndex: 'demandQuantity',
    key: 'demandQuantity',
  },
  {
    title: <Translation>{(t) => t('titles.Used_Quantity')}</Translation>,
    dataIndex: 'usedQuantity',
    key: 'usedQuantity',
  },
  {
    title: <Translation>{(t) => t('titles.Remaining_Quantity')}</Translation>,
    dataIndex: 'remainingQuantity',
    key: 'remainingQuantity',
  },
  {
    title: <Translation>{(t) => t('titles.Used_For')}</Translation>,
    dataIndex: 'usedFor',
    key: 'usedFor',
  },
  {
    title: <Translation>{(t) => t('titles.Used_Date')}</Translation>,
    dataIndex: 'usedDate',
    key: 'usedDate',
  },
];

function PreviewFormPartAndInventoryByPO(props: CommonPreviewProps<PaIModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const consumePO = useAppSelector(selectPaIConsumePO);

  const statusPartAndInventory = useAppSelector(selectPaIStatus);

  const listVendor = useAppSelector(selectVendorsList);
  const statusVendor = useAppSelector(selectVendorStatus);

  const listPartAndInventoryCategory = useAppSelector(selectPaICategoriesList);
  const statusPartAndInventoryCategory = useAppSelector(selectPaICategoryStatus);

  const listPartAndInventoryUnit = useAppSelector(selectPaIUnitsList);
  const statusPartAndInventoryUnit = useAppSelector(selectPaIUnitStatus);

  const listCostCenter = useAppSelector(selectCostCentersList);
  const statusCostCenter = useAppSelector(selectCostCenterStatus);

  const listCurrency = useAppSelector(selectCurrenciesList);
  const statusCurrency = useAppSelector(selectCurrencyStatus);

  useEffect(() => {
    if (!listPartAndInventoryCategory.length) {
      dispatch(getPaICategories());
    }
    if (!listVendor.length) {
      dispatch(getVendors());
    }
    if (!listPartAndInventoryUnit.length) {
      dispatch(getPaIUnits());
    }
    if (!listCostCenter.length) {
      dispatch(getCostCenters());
    }
    if (!listCurrency.length) {
      dispatch(getCurrencies());
    }

    dispatch(getPaIConsumeByPO(entity?.purchaseNumber || ''));
  }, []);

  const consume =
    consumePO && consumePO.length
      ? consumePO?.map((order) => ({
          ...order,
          usedDate: moment(order.usedDate).format(defaultFormatDate),
          ID: uuidv4(),
        }))
      : [];

  const valueVendor = listVendor.find((vendor) => vendor.partnerId === entity.partnerId);

  const valuePartAndInventoryCategory = listPartAndInventoryCategory.find(
    (category) =>
      category.partsAndInventoriesCategoryId ===
      entity.partsAndInventoriesCard.partsAndInventoriesCategoryId
  );

  const valuePropertyValues =
    entity.partsAndInventoriesPropertyDetails && entity.partsAndInventoriesPropertyDetails.length
      ? entity.partsAndInventoriesPropertyDetails.map((property) => ({
          value: property.value,
          propertyName: property?.partsAndInventoriesCardProperty?.name || '',
          key: property.partsAndInventoriesPropertyDetailId,
        }))
      : '';

  const valuePartAndInventoryUnit = listPartAndInventoryUnit.find(
    (unit) => unit.partsAndInventoriesUnitId === entity.partsAndInventoriesUnitId
  );

  const valueCostCenter = listCostCenter.find(
    (costCenter) => costCenter.costCenterId === entity.costCenterId
  );

  const valueCurrency = listCurrency.find((currency) => currency.currencyId === entity.currencyId);

  const loadingForm = checkStoreStatuses([
    statusPartAndInventory,
    statusVendor,
    statusPartAndInventoryCategory,
    statusPartAndInventoryUnit,
    statusCostCenter,
    statusCurrency,
  ]);

  return (
    <div
      style={{
        paddingBottom: 60,
      }}
    >
      <CommonForm
        isPreview
        loading={loadingForm}
        header={{
          title: entity.partsAndInventoriesCard.partsAndInventoriesCardName,
          onClickDelete: onDelete,
          hiddenDeleteButton: true,
          ...header,
        }}
        container={{
          style: { width: '100%', maxWidth: '100%' },
        }}
      >
        <CaseField md={24} lg={7}>
          <Item label={t('titles.Name')}>
            <PreviewField>
              {entity.partsAndInventoriesCard.partsAndInventoriesCardName}
            </PreviewField>
          </Item>
          <Item label={t('titles.Category')}>
            <PreviewField>
              {valuePartAndInventoryCategory?.partsAndInventoriesCategoryName}
            </PreviewField>
          </Item>
          <Item label={t('titles.Unit')}>
            <PreviewField>{valuePartAndInventoryUnit?.partsAndInventoriesUnitName}</PreviewField>
          </Item>
          <Item label={t('titles.Brand')}>
            <PreviewField>{entity?.partsAndInventoriesModel?.partsAndInventoriesCardBrand.name}</PreviewField>
          </Item>
          <Item label={t('titles.Model')}>
            <PreviewField>{entity?.partsAndInventoriesModel?.name}</PreviewField>
          </Item>
          <Item label={t('titles.Vendor')}>
            <PreviewField>{valueVendor?.name}</PreviewField>
          </Item>

          <Row
            wrap
            gutter={[10, 0]}
            style={{
              marginTop: 40,
            }}
            justify="space-between"
          >
            <Col span={12}>
              <Item>
                <Checkbox checked={entity.isPart} disabled={!entity.isPart}>
                  {t('titles.Parts')}
                </Checkbox>
              </Item>
            </Col>
            <Col span={12}>
              <Item>
                <Checkbox checked={entity.isInventory} disabled={!entity.isInventory}>
                  {t('titles.Inventory')}
                </Checkbox>
              </Item>
            </Col>
          </Row>
        </CaseField>
        <CaseField md={24} lg={7}>
          <Item label={t('titles.Serial_Number')}>
            <PreviewField>{entity?.serialNumber}</PreviewField>
          </Item>
          {valuePropertyValues && Boolean(valuePropertyValues.length) ? (
            <Item label={t('titles.Property_Value')}>
              {valuePropertyValues.map((property) => (
                <PreviewField key={property.key} typeField="property-value">
                  <span>{property.propertyName}:</span>
                  {property.value}
                </PreviewField>
              ))}
            </Item>
          ) : null}
          <Item label={t('titles.Site')}>
            <PreviewField>{entity?.partsAndInventoriesSite?.name}</PreviewField>
          </Item>
          <Item label={t('pages_single.Cost_Center')}>
            <PreviewField>{valueCostCenter?.name}</PreviewField>
          </Item>
          <Item label={t('titles.Purchase_Num')}>
            <PreviewField>{entity?.purchaseNumber}</PreviewField>
          </Item>
          <Item label={t('titles.Label')}>
            <PreviewField>
              {entity?.labels && entity.labels.length
                ? entity.labels.map((label) => (
                    <Label key={label.labelId} color={label.labelColor}>
                      {label.label}
                    </Label>
                  ))
                : ''}
            </PreviewField>
          </Item>
        </CaseField>
        <CaseField md={24} lg={7}>
          <Row gutter={[10, 0]} justify="space-between">
            <Col xl={8} md={12} sm={24}>
              <Item label={t('titles.Unit_Price')}>
                <PreviewField>{entity?.unitPrice}</PreviewField>
              </Item>
            </Col>
            <Col xl={8} md={12} sm={24}>
              <Item label={t('titles.Currency')}>
                <PreviewField>{valueCurrency?.name}</PreviewField>
              </Item>
            </Col>
            <Col xl={8} md={12} sm={24}>
              <Item label={t('titles.Quantity')}>
                <PreviewField>{entity?.quantity}</PreviewField>
              </Item>
            </Col>
          </Row>
          <Item label={t('titles.Status')}>
            <PreviewField>{entity?.status.name}</PreviewField>
          </Item>
        </CaseField>

        <CaseField md={24}>
          <SimpleTable dataSource={consume} rowKey={(row) => row.ID} columns={columns} />
        </CaseField>
      </CommonForm>
    </div>
  );
}

export { PreviewFormPartAndInventoryByPO };
