import { createSlice } from '@reduxjs/toolkit';
import { transformToPAINameObject } from '@shared/utils/functions';
import {
  addPaI,
  addPaIConsume,
  batchUpdatePaI,
  getPaIById,
  getPaIConsumeByCardId,
  getPaIConsumeByPO,
  getPaIs,
  getPaIsTypeName,
  getPaIsTypePO,
  updatePaI,
  updatePaIConsumeType,
  updatePaIFilterOptions,
} from '@store/actions/management/PaI';

import type { LoadingStatus } from '@constants/loadingStatus';
import type {
  FilterPaIDto,
  PaIConsumeNAMEModel,
  PaIConsumePOModel,
  PaIModel,
  PaITypeNAMEModel,
} from '@model/management/PaI';

interface PartAndInventoryState {
  listPartAndInventory: PaIModel[];
  listPartAndInventoryTypeName: PaITypeNAMEModel[];
  listPartAndInventoryTypeNameAllRecords: PaITypeNAMEModel[];
  listPartAndInventoryTypePOAllRecords: PaIModel[];
  consumePO: null | PaIConsumePOModel[];
  consumeNAME: null | PaIConsumeNAMEModel[];
  statusPartAndInventory: LoadingStatus;
  particularPartAndInventory: PaIModel | null;
  errorPartAndInventory: string | null;
  recordNotFound: boolean;
  filterOptions: FilterPaIDto;
  settingsPartAndInventory: {
    totalPage: number;
    totalRecords: number;
    consumeType: 1 | 2;
  };
}

const initialState: PartAndInventoryState = {
  listPartAndInventory: [],
  listPartAndInventoryTypeName: [],
  listPartAndInventoryTypeNameAllRecords: [],
  listPartAndInventoryTypePOAllRecords: [],
  statusPartAndInventory: 'idle',
  errorPartAndInventory: null,
  recordNotFound: false,
  particularPartAndInventory: null,
  consumePO: null,
  consumeNAME: null,
  filterOptions: {
    page: 1,
    perPage: 10,
    switchPoOrName: 1,
  },
  settingsPartAndInventory: {
    totalPage: 0,
    totalRecords: 0,
    consumeType: 1,
  },
};

const PaISlice = createSlice({
  name: 'partAndInventory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaIs.pending, (state) => {
        state.statusPartAndInventory = 'pending';
        state.errorPartAndInventory = null;
        state.recordNotFound = false;
      })
      .addCase(getPaIs.fulfilled, (state, action) => {
        if (action.payload.options) {
          state.filterOptions = {
            ...state.filterOptions,
            ...action.payload.options,
          };
        }

        if (
          action.payload?.options?.switchPoOrName === 1 ||
          state.filterOptions.switchPoOrName === 1
        ) {
          state.listPartAndInventory = action.payload.partsAndInventories as PaIModel[];
        }
        if (
          action.payload?.options?.switchPoOrName === 2 ||
          state.filterOptions.switchPoOrName === 2
        ) {
          state.listPartAndInventoryTypeName = action.payload
            .partsAndInventories as PaITypeNAMEModel[];
        }

        state.settingsPartAndInventory = {
          ...state.settingsPartAndInventory,
          ...action.payload.settingPartsAndInventories,
        };
        state.statusPartAndInventory = 'idle';
      })
      .addCase(getPaIs.rejected, (state, action) => {
        state.statusPartAndInventory = 'idle';
        if (action.payload?.error) {
          state.errorPartAndInventory = action.payload.error;
          state.recordNotFound = action.payload.error === 'RECORD_NOT_FOUND';
        }
        if (action.payload?.options) {
          state.filterOptions = {
            ...state.filterOptions,
            ...action.payload.options,
          };
        }
      });

    builder
      .addCase(getPaIsTypePO.pending, (state) => {
        state.statusPartAndInventory = 'pending';
        state.errorPartAndInventory = null;
      })
      .addCase(getPaIsTypePO.fulfilled, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        state.listPartAndInventoryTypePOAllRecords = payload;
      })
      .addCase(getPaIsTypePO.rejected, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        if (payload) {
          state.errorPartAndInventory = payload;
        }
      });

    builder
      .addCase(getPaIsTypeName.pending, (state) => {
        state.statusPartAndInventory = 'pending';
        state.errorPartAndInventory = null;
      })
      .addCase(getPaIsTypeName.fulfilled, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        state.listPartAndInventoryTypeNameAllRecords = payload;
      })
      .addCase(getPaIsTypeName.rejected, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        if (payload) {
          state.errorPartAndInventory = payload;
        }
      });

    builder
      .addCase(addPaIConsume.pending, (state) => {
        state.statusPartAndInventory = 'pending';
        state.errorPartAndInventory = null;
      })
      .addCase(addPaIConsume.fulfilled, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';

        if (state.filterOptions.switchPoOrName === 1) {
          state.listPartAndInventory = payload as PaIModel[];
        }
        if (state.filterOptions.switchPoOrName === 2) {
          state.listPartAndInventoryTypeName = payload as PaITypeNAMEModel[];
        }
      })
      .addCase(addPaIConsume.rejected, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        if (payload) {
          state.errorPartAndInventory = payload;
        }
      });

    builder.addCase(updatePaIFilterOptions.fulfilled, (state, { payload }) => {
      state.filterOptions = { ...state.filterOptions, ...payload };
    });

    builder.addCase(updatePaIConsumeType.fulfilled, (state, { payload }) => {
      state.settingsPartAndInventory.consumeType = payload;
      state.filterOptions.switchPoOrName = payload;
    });

    builder
      .addCase(addPaI.pending, (state) => {
        state.statusPartAndInventory = 'pending';
        state.errorPartAndInventory = null;
      })
      .addCase(addPaI.fulfilled, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        if (payload.purchaseNumber) {
          state.listPartAndInventory = [payload, ...state.listPartAndInventory];
        } else {
          const paiTypeNameObject = transformToPAINameObject(payload);
          state.listPartAndInventoryTypeName = [
            paiTypeNameObject,
            ...state.listPartAndInventoryTypeName,
          ];
        }
      })
      .addCase(addPaI.rejected, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        if (payload) {
          state.errorPartAndInventory = payload;
        }
      });

    builder
      .addCase(getPaIById.pending, (state) => {
        state.statusPartAndInventory = 'pending';
        state.errorPartAndInventory = null;
      })
      .addCase(getPaIById.fulfilled, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        state.particularPartAndInventory = payload;
      })
      .addCase(getPaIById.rejected, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        if (payload) {
          state.errorPartAndInventory = payload;
        }
      });

    builder
      .addCase(updatePaI.pending, (state) => {
        state.statusPartAndInventory = 'pending';
        state.errorPartAndInventory = null;
      })
      .addCase(updatePaI.fulfilled, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        if (state.filterOptions.switchPoOrName === 1) {
          state.listPartAndInventory = payload.partsAndInventories as PaIModel[];
        }
        if (state.filterOptions.switchPoOrName === 2) {
          state.listPartAndInventoryTypeName = payload.partsAndInventories as PaITypeNAMEModel[];
        }
        state.particularPartAndInventory = payload.particularPartAndInventory;
      })
      .addCase(updatePaI.rejected, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        if (payload) {
          state.errorPartAndInventory = payload;
        }
      });

    builder
      .addCase(batchUpdatePaI.pending, (state) => {
        state.errorPartAndInventory = null;
      })
      .addCase(batchUpdatePaI.fulfilled, (state) => {
        state.particularPartAndInventory = null;
      })
      .addCase(batchUpdatePaI.rejected, (state, { payload }) => {
        if (payload) {
          state.errorPartAndInventory = payload;
        }
      });

    /* --------------------------------- Consume -------------------------------- */
    builder
      .addCase(getPaIConsumeByPO.pending, (state) => {
        state.statusPartAndInventory = 'pending';
        state.errorPartAndInventory = null;
      })
      .addCase(getPaIConsumeByPO.fulfilled, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        state.consumePO = payload;
      })
      .addCase(getPaIConsumeByPO.rejected, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        state.consumePO = null;
        if (payload) {
          state.errorPartAndInventory = payload;
        }
      });
    builder
      .addCase(getPaIConsumeByCardId.pending, (state) => {
        state.statusPartAndInventory = 'pending';
        state.errorPartAndInventory = null;
      })
      .addCase(getPaIConsumeByCardId.fulfilled, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        state.consumeNAME = payload;
      })
      .addCase(getPaIConsumeByCardId.rejected, (state, { payload }) => {
        state.statusPartAndInventory = 'idle';
        state.consumeNAME = null;
        if (payload) {
          state.errorPartAndInventory = payload;
        }
      });
  },
});

const PaIReducer = PaISlice.reducer;

export { PaIReducer };
