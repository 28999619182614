import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormPartAndInventoryConsume } from '@components/views/Forms/managment';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addPaIConsume } from '@store/actions/management/PaI';

import type { ConsumePaIDto } from '@model/management/PaI';

const ConsumePartAndInventoryV2 = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { paiAccess } = useGetAccess();

  const onFinish = async (dto: ConsumePaIDto) => {
    try {
      await dispatch(addPaIConsume(dto)).unwrap();
      ApiNotifications.create(t('titles.Consume'));
      navigate(paths.partAndInventory.default);
      debugger;
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={paiAccess.consume} redirectTo={paths.partAndInventory.default}>
      <FormPartAndInventoryConsume
        onFinish={onFinish}
        header={{
          title: t('titles.Consume'),
        }}
      />
    </CheckAccess>
  );
};

export default ConsumePartAndInventoryV2;
