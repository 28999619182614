import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, PAIPropertyValues, SelectLabels } from '@components/common';
import { useAppDispatch, useAppSelector, useModalState } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { Checkbox, Input, Select } from '@shared/UI';
import { replaceStrToNum } from '@shared/utils/functions';
import { getCurrencies } from '@store/actions/extra/Currency';
import { addLabel } from '@store/actions/extra/Label';
import { addCostCenter, getCostCenters } from '@store/actions/properties/CostCenter';
import { addPaIBrand, getPaIBrands } from '@store/actions/properties/PaI/Brand';
import { addPaICard, getPaICards } from '@store/actions/properties/PaI/Card';
import { addPaIModel, getPaIModels } from '@store/actions/properties/PaI/Model';
import { addPaIProperty } from '@store/actions/properties/PaI/Property';
import { addPaIStatus ,getPaIStatuses } from '@store/actions/properties/PaI/Status';
import { addSite, getSites } from '@store/actions/properties/Site';
import { addVendor, getVendors } from '@store/actions/properties/Vendor';
import { selectCurrenciesList, selectCurrencyStatus } from '@store/selectors/extra/Currency';
import { selectPaIStatus } from '@store/selectors/management/PaI';
import {
  selectCostCentersList,
  selectCostCenterStatus,
} from '@store/selectors/properties/CostCenter';
import { selectPaIBrandsList, selectPaIBrandStatus } from '@store/selectors/properties/PaI/Brand';
import { selectPaICardsList, selectPaICardStatus } from '@store/selectors/properties/PaI/Card';
import { selectPaIModelsList, selectPaIModelStatus } from '@store/selectors/properties/PaI/Model';
import { selectPaIStatusesList, selectPaIStatusStatus } from '@store/selectors/properties/PaI/Status';
import { selectSitesList, selectSiteStatus } from '@store/selectors/properties/Site';
import { selectVendorsList, selectVendorStatus } from '@store/selectors/properties/Vendor';
import { Col, Row } from 'antd';

import { CreateableEntityModals } from './CreateableEntityModals';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateLabelDto } from '@model/extra/Label';
import type { CreatePaIDto } from '@model/management/PaI';
import type { CreateCostCenterDto } from '@model/properties/CostCenter';
import type { CreatePaIBrandDto } from '@model/properties/PaI/Brand';
import type { CreatePaICardDto } from '@model/properties/PaI/Card';
import type { CreatePaIModelDto } from '@model/properties/PaI/Model';
import type { CreatePaIPropertyDto } from '@model/properties/PaI/Property';
import type { CreatePaIStatusDto } from '@model/properties/PaI/Status';
import type { CreateSiteDto } from '@model/properties/Site';
import type { CreateVendorDto } from '@model/properties/Vendor';
import type { FormInstance } from 'antd';
import type { FormFinishInfo, FormProviderProps } from 'rc-field-form/lib/FormContext';

const { CaseField, Item, PreviewField, useForm, useWatch, Provider } = CommonForm;

type FormsFinish = {
  formPaIName: FormInstance<CreatePaICardDto>

  formPaIBrand: FormInstance<CreatePaIBrandDto>;

  formPaIModel: FormInstance<CreatePaIModelDto>;

  formPaIProperty: FormInstance<CreatePaIPropertyDto>;

  formPaIStatus: FormInstance<CreatePaIStatusDto>;

  formCostCenter: FormInstance<CreateCostCenterDto>;

  formVendor: FormInstance<CreateVendorDto>;

  formSite: FormInstance<CreateSiteDto>;

  formLabel: FormInstance<CreateLabelDto>;
};

function FormPartAndInventory(props: CommonFormProps<CreatePaIDto>) {
  const { initialValues, ...rest } = props;
  const [form] = useForm<CreatePaIDto>();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();



    /* -------------------------------  Modals State ------------------------------ */
    const paINameModal = useModalState();
    const paIBrandModal = useModalState();
    const paIModelModal = useModalState();
    const paIPropertyModal = useModalState();
    const paIStatusModal = useModalState();
    const costCenterModal = useModalState();
    const labelModal = useModalState();
    const vendorModal = useModalState();
    const siteModal = useModalState();

  /* ---------------------- Data Part And Inventory Form ---------------------- */
  const statusPartAndInventory = useAppSelector(selectPaIStatus);

  const listPartAndInventoryBrand = useAppSelector(selectPaIBrandsList);
  const statusPartAndInventoryBrand = useAppSelector(selectPaIBrandStatus);

  const listPartAndInventoryName = useAppSelector(selectPaICardsList);
  const statusPartAndInventoryName = useAppSelector(selectPaICardStatus);

  const listPartAndInventoryModel = useAppSelector(selectPaIModelsList);
  const statusPartAndInventoryModel = useAppSelector(selectPaIModelStatus);
  
  const listPartAndInventoryStatus = useAppSelector(selectPaIStatusesList);
  const statusPartAndInventoryStatus = useAppSelector(selectPaIStatusStatus);

  const listVendor = useAppSelector(selectVendorsList);
  const statusVendor = useAppSelector(selectVendorStatus);

  const listSite = useAppSelector(selectSitesList);
  const statusSite = useAppSelector(selectSiteStatus);

  const listCostCenter = useAppSelector(selectCostCentersList);
  const statusCostCenter = useAppSelector(selectCostCenterStatus);

  const listCurrency = useAppSelector(selectCurrenciesList);
  const statusCurrency = useAppSelector(selectCurrencyStatus);

  useEffect(() => {
    if (!listPartAndInventoryBrand.length) {
      dispatch(getPaIBrands());
    }
    if (!listPartAndInventoryName.length) {
      dispatch(getPaICards());
    }
    if (!listPartAndInventoryModel.length) {
      dispatch(getPaIModels());
    }
    if (!listPartAndInventoryStatus.length) {
      dispatch(getPaIStatuses());
    }
    if (!listVendor.length) {
      dispatch(getVendors());
    }
    if (!listSite.length) {
      dispatch(getSites());
    }
    if (!listCostCenter.length) {
      dispatch(getCostCenters());
    }

    if (!listCurrency.length) {
      dispatch(getCurrencies());
    }
  }, []);

  const brandId = useWatch<number | null>('partsAndInventoriesCardBrandId', form);
  const nameId = useWatch<number | null>('partsAndInventoriesCardId', form);

  const selectedName = listPartAndInventoryName.filter(name => name.partsAndInventoriesCardId === nameId)[0];  

  useEffect(() => {
    if(selectedName) {
      form.setFieldValue('partsAndInventoriesCategoryId', selectedName.partsAndInventoriesCategoryId)
      form.setFieldValue('partsAndInventoriesUnitId', selectedName.partsAndInventoriesUnitId)
    }
  }, [nameId])

  const modelOptions = listPartAndInventoryModel.filter((model) => {
    if (!brandId) return false;

    if (model.partsAndInventoriesCardBrandId === brandId) {
      return true;
    }
    return false;
  });

  const [focus, setFocus] = useState(false);

  const onFieldFocus = () => setFocus(true);
  const onFieldBlur = () => setFocus(false);

  const handleValueFromBrand = (value: number | null) => {
    form.setFieldValue('partsAndInventoriesModelId', null);
    return value;
  };

  const onFinishPaIName = (paINameForm: FormInstance<CreatePaICardDto>) => {
    const dto = paINameForm.getFieldsValue();

    dispatch(addPaICard(dto))
      .unwrap()
      .then(({ partsAndInventoriesCardId, partsAndInventoriesCardName }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: partsAndInventoriesCardName,
            section: `${t('pages_single.PaI')} ${t('pages_single.Attribute')}`,
          })
        });

        paINameForm.resetFields();
        form.setFieldValue('partsAndInventoriesCardId', partsAndInventoriesCardId);
        paINameModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishPaIBrand = (paIBrandForm: FormInstance<CreatePaIBrandDto>) => {
    const dto = paIBrandForm.getFieldsValue();

    dispatch(addPaIBrand(dto))
      .unwrap()
      .then(({ partsAndInventoriesCardBrandId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: `${t('pages_single.PaI')} ${t('pages_single.Attribute')}`,
          })
        });

        paIBrandForm.resetFields();
        form.setFieldValue('partsAndInventoriesCardBrandId', partsAndInventoriesCardBrandId);
        paIBrandModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishPaIModel = (paIModelForm: FormInstance<CreatePaIModelDto>) => {
    const dto = paIModelForm.getFieldsValue();

    dispatch(addPaIModel(dto))
      .unwrap()
      .then(({ partsAndInventoriesCardModelId, partsAndInventoriesCardBrandId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: `${t('pages_single.PaI')} ${t('pages_single.Attribute')}`,
          })
        });

        paIModelForm.resetFields();
        form.setFieldValue('partsAndInventoriesCardBrandId', partsAndInventoriesCardBrandId);
        form.setFieldValue('partsAndInventoriesModelId', partsAndInventoriesCardModelId);
        paIModelModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishPaIProperty = (paIPropertyForm: FormInstance<CreatePaIPropertyDto>) => {
    const dto = paIPropertyForm.getFieldsValue();

    dispatch(addPaIProperty(dto))
      .unwrap()
      .then(({ partsAndInventoriesCardPropertyId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: `${t('pages_single.PaI')} ${t('pages_single.Attribute')}`,
          })
        });

        paIPropertyForm.resetFields();
        form.setFieldValue('partsAndInventoriesCardPropertyId', partsAndInventoriesCardPropertyId);
        paIPropertyModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishPaIStatus = (paIStatusForm: FormInstance<CreatePaIStatusDto>) => {
    const dto = paIStatusForm.getFieldsValue();

    dispatch(addPaIStatus(dto))
      .unwrap()
      .then(({ partsAndInventoriesStatusId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: `${t('pages_single.PaI')} ${t('pages_single.Attribute')}`,
          })
        });

        paIStatusForm.resetFields();
        form.setFieldValue('statusId', partsAndInventoriesStatusId);
        paIStatusModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishCostCenter = (costCenterForm: FormInstance<CreateCostCenterDto>) => {
    const dto = costCenterForm.getFieldsValue();

    dispatch(addCostCenter(dto))
      .unwrap()
      .then(({ costCenterId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Cost_Center'),
          })
        });

        costCenterForm.resetFields();
        form.setFieldValue('costCenterId', costCenterId);
        costCenterModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishLabel = (labelForm: FormInstance<CreateLabelDto>) => {
    const dto = labelForm.getFieldsValue();

    dispatch(addLabel({ label: dto, enumLabelPlace: 2 }))
      .unwrap()
      .then(({ createdLabel }) => {
        labelForm.resetFields();
        const newLabel = {
          label: createdLabel.label,
          value: createdLabel.label,
          labelColor: createdLabel.labelColor,
        };
        const labelList = form.getFieldValue('labelsList');

        form.setFieldValue('labelsList', [...labelList, newLabel]);
        labelModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };
  const onFinishVendor = (vendorForm: FormInstance<CreateVendorDto>) => {
    const dto = vendorForm.getFieldsValue();

    dispatch(addVendor(dto))
      .unwrap()
      .then(({ partnerId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Vendor'),
          })
        });

        vendorForm.resetFields();
        form.setFieldValue('partnerId', partnerId);
        vendorModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishSite = (siteForm: FormInstance<CreateSiteDto>) => {
    const dto = siteForm.getFieldsValue();

    dispatch(addSite(dto))
      .unwrap()
      .then(({createdSite: { siteId, name }}) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Site'),
          })
        });

        siteForm.resetFields();
        form.setFieldValue('partsAndInventoriesSiteId', siteId);
        siteModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFormsFinish = (
    name: string,
    { forms }: Omit<FormFinishInfo, 'forms'> & { forms: FormsFinish }
  ) => {
    switch (name) {
      case 'formPaIName': {
        onFinishPaIName(forms.formPaIName);
        break;
      }
      case 'formPaIBrand': {
        onFinishPaIBrand(forms.formPaIBrand);
        break;
      }
      case 'formPaIModel': {
        onFinishPaIModel(forms.formPaIModel);
        break;
      }
      case 'formPaIProperty': {
        onFinishPaIProperty(forms.formPaIProperty);
        break;
      }
      case 'formPaIStatus': {
        onFinishPaIStatus(forms.formPaIStatus);
        break;
      }
      case 'formCostCenter': {
        onFinishCostCenter(forms.formCostCenter);
        break;
      }
      case 'formVendor': {
        onFinishVendor(forms.formVendor);
        break
      }
      case 'formSite': {
        onFinishSite(forms.formSite);
        break
      }
      case 'formLabel': {
        onFinishLabel(forms.formLabel);
      }
    }
  };

  const notFoundModel = (
    <div className="right-section-not-found">
      <p
        className="right-section-not-found-text">{!brandId ?
          t('titles.Please_Choose_Before', { name: t('titles.Brand') })
          : t('titles.Not_Found')}
      </p>
    </div>
  )
  

  return (
    <Provider onFormFinish={onFormsFinish as FormProviderProps['onFormFinish']}>
    <CommonForm
      container={{
        style: { width: '100%', maxWidth: '100%' },
      }}
      form={form}
      loading={statusPartAndInventory === 'pending'}
      initialValues={{
        ...initialValues,
        isInventory: initialValues?.isInventory ? initialValues?.isInventory : false,
        isPart: initialValues?.isPart ? initialValues?.isPart : false,
        labelsList: initialValues?.labelsList ? initialValues?.labelsList : [],
        partsAndInventoriesPropertyDetalisModelList:
          initialValues?.partsAndInventoriesPropertyDetalisModelList
            ? initialValues?.partsAndInventoriesPropertyDetalisModelList
            : [],
        currencyId: initialValues?.currencyId ? initialValues?.currencyId : null,
        costCenterId: initialValues?.costCenterId ? initialValues?.costCenterId : null,
        partnerId: initialValues?.partnerId ? initialValues?.partnerId : null,
        partsAndInventoriesModelId: initialValues?.partsAndInventoriesModelId
          ? initialValues?.partsAndInventoriesModelId
          : null,
        partsAndInventoriesUnitId: initialValues?.partsAndInventoriesUnitId
          ? initialValues?.partsAndInventoriesUnitId
          : null,
        purchaseNumber: initialValues?.purchaseNumber ? initialValues?.purchaseNumber : null,
        quantity: initialValues?.quantity ? initialValues?.quantity : null,
        serialNumber: initialValues?.serialNumber ? initialValues?.serialNumber : null,
        partsAndInventoriesSiteId: initialValues?.partsAndInventoriesSiteId
          ? initialValues?.partsAndInventoriesSiteId
          : null,
        unitPrice: initialValues?.unitPrice ? initialValues?.unitPrice : null,
      }}
      {...rest}
    >
      <CaseField md={24} lg={7}>
        <Item
          label={t('titles.Name')}
          name="partsAndInventoriesCardId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
            loading={statusPartAndInventoryName === 'pending'}
            placeholder={t('titles.Name')}
            options={listPartAndInventoryName}
            fieldNames={{
              label: 'partsAndInventoriesCardName',
              value: 'partsAndInventoriesCardId',
            }}
            isCreatable
            creatableAction={paINameModal.actions.onOpen}
          />
        </Item>
        <Item label={t('titles.Category')} name="partsAndInventoriesCategoryId">
          <PreviewField typeField={focus ? 'input-focus' : 'input'}>
            {selectedName ? selectedName.partsAndInventoriesCategory.partsAndInventoriesCategoryName : ''}
          </PreviewField>
        </Item>
        <Item label={t('titles.Unit')} name="partsAndInventoriesUnitId">
          <PreviewField typeField={focus ? 'input-focus' : 'input'}>
            {selectedName ? selectedName.partsAndInventoriesUnit.partsAndInventoriesUnitName : ''}
          </PreviewField>
        </Item>
        <Item label={t('titles.Brand')} name="partsAndInventoriesCardBrandId" getValueFromEvent={handleValueFromBrand}>
          <Select
            loading={statusPartAndInventoryBrand === 'pending'}
            placeholder={t('titles.Choose_Name', { name: t('titles.Brand') })}
            options={listPartAndInventoryBrand}
            fieldNames={{
              label: 'name',
              value: 'partsAndInventoriesCardBrandId',
            }}
            isCreatable
            creatableAction={paIBrandModal.actions.onOpen}
          />
        </Item>
        <Item label={t('titles.Model')} name="partsAndInventoriesModelId">
          <Select
            loading={statusPartAndInventoryModel === 'pending'}
            placeholder={t('titles.Choose_Name', { name: t('titles.Model') })}
            options={modelOptions}
            fieldNames={{
              label: 'name',
              value: 'partsAndInventoriesCardModelId',
            }}
            notFoundContent={notFoundModel}
            isCreatable
            creatableAction={paIModelModal.actions.onOpen}
          />
        </Item>
        <Item label={t('titles.Vendor')} name="partnerId">
          <Select
            loading={statusVendor === 'pending'}
            placeholder={t('titles.Choose_Name', { name: t('titles.Vendor') })}
            options={listVendor}
            fieldNames={{
              label: 'name',
              value: 'partnerId',
            }}
            isCreatable
            creatableAction={vendorModal.actions.onOpen}
          />
        </Item>

        <Row
          wrap
          gutter={[10, 0]}
          style={{
            marginTop: 40,
          }}
          justify="space-between"
        >
          <Col span={12}>
            <Item name="isPart" valuePropName="checked">
              <Checkbox checked>{t('titles.Parts')}</Checkbox>
            </Item>
          </Col>
          <Col span={12}>
            <Item name="isInventory" valuePropName="checked">
              <Checkbox>{t('titles.Inventory')}</Checkbox>
            </Item>
          </Col>
        </Row>
      </CaseField>
      <CaseField md={24} lg={7}>
        <Item label={t('titles.Serial_Number')} name="serialNumber">
          <Input placeholder={t('titles.Serial_Number')} />
        </Item>
        <PAIPropertyValues
          initialValues={initialValues?.partsAndInventoriesPropertyDetalisModelList}
          isCreatable
          creatableAction={paIPropertyModal.actions.onOpen}
        />
        <Item
          label={t('titles.Site')}
          name="partsAndInventoriesSiteId"
          rules={[{ required: true }]}
        >
          <Select
            loading={statusSite === 'pending'}
            placeholder={t('titles.Choose_Name', { name: t('titles.Site') })}
            options={listSite}
            fieldNames={{
              label: 'name',
              value: 'siteId',
            }}
            isCreatable
            creatableAction={siteModal.actions.onOpen}
          />
        </Item>
        <Item label={t('pages_single.Cost_Center')} name="costCenterId">
          <Select
            loading={statusCostCenter === 'pending'}
            placeholder={t('titles.Choose_Name', { name: t('pages_single.Cost_Center') })}
            options={listCostCenter}
            fieldNames={{
              label: 'name',
              value: 'costCenterId',
            }}
            isCreatable
            creatableAction={costCenterModal.actions.onOpen}
          />
        </Item>
        <Item label={t('titles.Purchase_Num')} name="purchaseNumber">
          <Input placeholder={t('titles.Purchase_Num')} />
        </Item>

        <SelectLabels 
          enumLabelPlace={2} 
          isCreatable
          creatableAction={labelModal.actions.onOpen}
        />
      </CaseField>
      <CaseField md={24} lg={7}>
        <Row gutter={[10, 0]} wrap justify="space-between">
          <Col xl={8} md={12} sm={24}>
            <Item
              rules={[{ required: true }]}
              label={t('titles.Unit_Price')}
              name="unitPrice"
              normalize={(value) => {
                return replaceStrToNum(value);
              }}
            >
              <Input placeholder="00.00" />
            </Item>
          </Col>
          <Col xl={8} md={12} sm={24}>
            <Item rules={[{ required: true }]} label={t('titles.Currency')} name="currencyId">
              <Select
                placeholder="USD"
                loading={statusCurrency === 'pending'}
                options={listCurrency}
                fieldNames={{ label: 'name', value: 'currencyId' }}
              />
            </Item>
          </Col>
          <Col xl={8} md={12} sm={24}>
            <Item
              rules={[{ required: true }]}
              label={t('titles.Quantity')}
              name="quantity"
              normalize={(value) => {
                return replaceStrToNum(value);
              }}
            >
              <Input placeholder="0" />
            </Item>
          </Col>
        </Row>
        <Item label={t('titles.Status')} name="statusId" rules={[{ required: true }]}>
          <Select
            placeholder={t('titles.Choose_Name', { name: t('titles.Status') })}
            options={listPartAndInventoryStatus}
            loading={statusPartAndInventoryStatus === 'pending'}
            fieldNames={{
              label: 'name',
              value: 'partsAndInventoriesStatusId',
            }}
            isCreatable
            creatableAction={paIStatusModal.actions.onOpen}
          />
        </Item>
      </CaseField>

      <CreateableEntityModals 
        paINameModal={paINameModal}
        paIBrandModal={paIBrandModal}
        paIModelModal={paIModelModal}
        paIPropertyModal={paIPropertyModal}
        paIStatusModal = {paIStatusModal}
        costCenterModal={costCenterModal}
        labelModal={labelModal}
        vendorModal={vendorModal}
        siteModal={siteModal}
      />
    </CommonForm>
    </Provider>
  );
}

export { FormPartAndInventory };
